<div *ngIf="evalone" class="container eval-sec ">
<div class="row pb-3 ">
  <div class="col-md-11 mx-auto">
    <h1>Participant Information</h1>
    <p>Thank you for participating in
      <strong>
        <em>{{displayTitle()}}.
        </em>
      </strong>
  </div>
</div>



<form [formGroup]="evalFormGroup" [class.warning]="!evalFormGroup.valid && evalFormGroup.dirty" (ngSubmit)="onSubmit(evalFormGroup.value)" #evalForm="ngForm">
<p class="form-error" *ngIf="!evalFormGroup.valid && evalForm.submitted"></p>
  <div class="row py-4">
    <div class="col-md-11 mx-auto">
      <div class="form-group pb-3">
        <label for="fname">First Name
        </label>
        <input type="text"  placeholder="First Name" id="fname" class="form-control">
        <label for="mname">Middle Initial
        </label>
        <input type="text"  placeholder="Mi" id="mname" class="form-control">
        <label for="lname">Last Name
        </label>
        <input type="text"  placeholder="Last Name" id="lname" class="form-control">
        <label for="pnum">Daytime Phone Number
        </label>
        <input type="text"  placeholder="##" id="pnum" class="form-control">
        <label for="email">Email Address
        </label>
        <input type="text"  placeholder="Email" id="email" class="form-control">
      </div>
      <fieldset class="form-group" formGroupName="profession"  [ngClass]="{ 'has-error': this.evalFormGroup.get('profession').status === 'INVALID' && evalForm.submitted }">
        <div class="row ">
          <legend class="col-form-label col-sm-3 pt-0">Profession
            <span *ngIf="isRequiredField(professionCtrl)">*</span>
          </legend>
          <div class="col-sm-9">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let pro of professions">
              <input type="radio" formControlName="proff" [value]="pro.title" [id]="'profession' + pro.id" class="form-check-input mb-1">
              <label class="form-check-label" [for]="'profession' + pro.id">{{ pro.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="form-group row"  [ngClass]="{ 'has-error': this.evalFormGroup.get('specialty').status === 'INVALID' && evalForm.submitted }">
        <label for="specialty" class="col-sm-3 col-form-label">
          Specialty
          <span *ngIf="isRequiredField(specialtyCtrl)">*</span>
        </label>
        <div class="col-sm-9">
          <input type="text" id="specialty" [formControl]="specialtyCtrl" placeholder="" class="form-control">
        </div>
      </div>


      <fieldset class="form-group" formGroupName="avgPatient"  [ngClass]="{ 'has-error': this.evalFormGroup.get('avgPatient').status === 'INVALID' && evalForm.submitted }">
        <div class="row">
          <legend class="col-form-label col-sm-3 pt-0">On average, how many patients with polycystic ovary syndrome (PCOS) do you see in 1 week?
            <span *ngIf="isRequiredField(professionCtrl)">*</span>
          </legend>
          <div class="col-sm-9 align-self-center">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let avg of patientAvg">
              <input type="radio" formControlName="patientCount" [value]="avg.title" [id]="'patientAvg' + avg.id" class="form-check-input mb-1">
              <label class="form-check-label" [for]="'patientAvg' + avg.id">{{ avg.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>

    </div>
  </div>
  <hr>
  <div class="row py-4">

  <div class="col-md-11 mx-auto">
  
      <h3>Continuing Education Credit Attestation</h3>
  
  <p>
      <em>
          In order to receive credit for this program, please review the
          following statements and sign the attestation below.
      </em>
  </p>
  <p>
      <em>For Physicians</em>
  </p>
  <p>
      The Institute for Medical and Nursing Education, Inc. (IMNE), is accredited
      by the Accreditation Council for Continuing Medical Education (ACCME) to
      provide continuing medical education for physicians.
  </p>
  <p>
  IMNE designates this enduring activity for 0.5    <em>AMA PRA Category 1 Credits</em>™. Physicians should claim only the
      credit commensurate with the extent of their participation in the activity.
  </p>
  <p>
      <em>For Nurses</em>
  </p>
  <p>
      IMNE is accredited as a provider of nursing continuing professional
      development by the American Nurses Credentialing Centers (ANCCs)
      Commission on Accreditation.
  </p>
  <p>
      This educational program provides 0.5 contact hours of continuing
      education credit.
  </p><br/>
  <div class="row pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('time').status === 'INVALID' && evalForm.submitted }">
    <div class="col-sm-6">
      <label>I certify that I completed this educational activity. The actual amount of time I spent in this activity was:</label>
    </div>
    <div class="col-sm-6">
      <div class="form-group" [ngClass]="{ 'has-error': this.evalFormGroup.get('time').status === 'INVALID' && evalForm.submitted }">
        <label for="timeCtrl">
          Time
          <span *ngIf="isRequiredField(timeCtrl)">*</span>
        </label>
        <select class="form-control" id="timeCtrl" [formControl]="timeCtrl" ngModel>
          <option value="" disabled>Choose a time</option>
          <option *ngFor="let option of timeMultiselectOptions2" [ngValue]="option.id" [id]="'time' + option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <fieldset class="form-group pb-3" formGroupName="certificateType" [ngClass]="{ 'has-error': this.evalFormGroup.get('certificateType').status === 'INVALID' && evalForm.submitted }">
    <div class="row">
      <legend class="col-form-label col-sm-5">CME/CE Certificate Type
        <span *ngIf="isRequiredField(certificateTypeCtrl)">*</span>
      </legend>
      <div class="col-sm-7  align-self-center text-center text-sm-right">
        <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let certType of certTypes">
          <input type="radio" formControlName="type" [value]="certType.title" [id]="'certificateType' + certType.id" class="form-check-input mr-0 mb-1">
          <label class="form-check-label" [for]="'certificateType' + certType.id">{{ certType.title }}</label>
        </div>
      </div>
    </div>
  </fieldset>

  <div class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('name').status === 'INVALID' && evalForm.submitted }">
    <label for="fullName">Enter your full name (as it should appear on your certificate).
      <span *ngIf="isRequiredField(nameCtrl)">*</span>
    </label>
    <input type="text" [formControl]="nameCtrl" placeholder="Full Name" id="fullName" class="form-control">
  </div>
  </div>
  </div>
  <hr>
  <div class="row py-4">
    <div class="col-md-11 mx-auto">

      <h3>Faculty and Content</h3>
      <p>
        <strong>Please evaluate the content of this activity.</strong>
      </p>
      <p class="rating-legend">Rating Scale:
        <span>1 = Strongly Disagree&nbsp;</span>
        <span>&nbsp;5 = Strongly Agree</span>
      </p>
      <p>
        <strong>
          <em>Andrea Dunaif, MD</em><br/>
          More Cycles in Women’s Health: The Complicated Relationship Between Obesity and PCOS
        </strong>
      </p>

      <fieldset class="form-group" formGroupName="evidenceBased" [ngClass]="{ 'has-error': this.evalFormGroup.get('evidenceBased').status === 'INVALID' && evalForm.submitted }">
        <div class="row">
          <legend class="col-form-label col-sm-7 pt-0">Information provided in this presentation will promote the practice of evidence-based medicine
            <span *ngIf="isRequiredField(evidenceBasedCtrl)">*</span>
          </legend>
          <div class="col-sm-5 align-self-center text-center text-sm-right">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
              <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'patientAvg' + ranking.id" class="form-check-input mr-0 mb-1">
              <label class="form-check-label" [for]="'evidenceBased' + ranking.id">{{ ranking.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="form-group" formGroupName="biasFree" [ngClass]="{ 'has-error': this.evalFormGroup.get('biasFree').status === 'INVALID' && evalForm.submitted }">
        <div class="row">
          <legend class="col-form-label col-sm-6 pt-0">The overall content of this activity was objective and free of bias.
            <span *ngIf="isRequiredField(biasFreeCtrl)">*</span>
          </legend>
          <div class="col-sm-6 align-self-center text-center text-sm-right">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let opt of yesNoOpts">
              <input type="radio" formControlName="rank" [value]="opt.title" [id]="'biasFree' + opt.id" class="form-check-input mr-0 mb-1">
              <label class="form-check-label" [for]="'biasFree' + opt.id">{{ opt.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group" [ngClass]="{ 'has-error': this.evalFormGroup.get('biasExplanation').status === 'INVALID' && evalForm.submitted }">
        <label for="biasExplanation">If you answered "no" to the previous statement, please explain why.
          <span *ngIf="isRequiredField(biasExplanationCtrl)">*</span>
        </label>
        <textarea [formControl]="biasExplanationCtrl" class="form-control" id="biasExplanation"></textarea>
      </div>

    </div>
  </div>
  <hr>
  <div class="row py-4">
    <div class="col-md-11 mx-auto">
      <h3>Learning Objectives</h3>
      <p>
        <strong>Please evaluate how well the following learning objectives were met.</strong>
      </p>
      <p class="rating-legend">Rating Scale:
        <span>1 = Not Met&nbsp;</span>
        <span>&nbsp;5 = Met</span>
      </p>

      <fieldset class="form-group" formGroupName="describeGLP" [ngClass]="{ 'has-error': this.evalFormGroup.get('describeGLP').status === 'INVALID' && evalForm.submitted }" >
        <div class="row">
          <legend class="col-form-label col-sm-6 pt-0">Describe the relationship between excess weight/obesity and women’s health (ie, PCOS, infertility, assisted reproduction techniques, urinary incontinence)
            <span *ngIf="isRequiredField(describeGLPCtrl)">*</span>
          </legend>
          <div class="col-sm-6 align-self-center text-center text-sm-right">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
              <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'describeGLPC' + ranking.id" class="form-check-input mr-0 mb-1">
              <label class="form-check-label" [for]="'describeGLPC' + ranking.id">{{ ranking.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>



      

    </div>
  </div>
  <hr>
  <div class="row py-4">
    <div class="col-md-11 mx-auto">

      <h3>Overall Activity Evaluation</h3>
      <p>
        <strong>Please evaluate the overall activity.</strong>
      </p>
      <p class="rating-legend">Rating Scale:
        <span>1 = Strongly Disagree&nbsp;</span>
        <span>&nbsp;5 = Strongly Agree</span>
      </p>

      <fieldset class="form-group" formGroupName="relevant" [ngClass]="{ 'has-error': this.evalFormGroup.get('relevant').status === 'INVALID' && evalForm.submitted }">
        <div class="row">
          <legend class="col-form-label col-sm-6 pt-0">The educational content was useful and relevant to my practice.
            <span *ngIf="isRequiredField(relevantCtrl)">*</span>
          </legend>
          <div class="col-sm-6 align-self-center text-center text-sm-right">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
              <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'relevant' + ranking.id" class="form-check-input mr-0 mb-1">
              <label class="form-check-label" [for]="'relevant' + ranking.id">{{ ranking.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="form-group" formGroupName="educational" [ngClass]="{ 'has-error': this.evalFormGroup.get('educational').status === 'INVALID' && evalForm.submitted }">
        <div class="row">
          <legend class="col-form-label col-sm-6 pt-0">The educational format was effective.
            <span *ngIf="isRequiredField(educationalCtrl)">*</span>
          </legend>
          <div class="col-sm-6 align-self-center text-center text-sm-right">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
              <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'educational' + ranking.id" class="form-check-input mr-0 mb-1">
              <label class="form-check-label" [for]="'educational' + ranking.id">{{ ranking.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="form-group" formGroupName="knowledge" [ngClass]="{ 'has-error': this.evalFormGroup.get('knowledge').status === 'INVALID' && evalForm.submitted }">
        <div class="row">
          <legend class="col-form-label col-sm-6 pt-0">The information provided in this presentation enhanced my knowledge and will enable me to provide better care for my patients with PCOS.
            <span *ngIf="isRequiredField(knowledgeCtrl)">*</span>
          </legend>
          <div class="col-sm-6 align-self-center text-center text-sm-right">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
              <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'knowledge' + ranking.id" class="form-check-input mr-0 mb-1">
              <label class="form-check-label" [for]="'knowledge' + ranking.id">{{ ranking.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="form-group pb-3" formGroupName="confidence" [ngClass]="{ 'has-error': this.evalFormGroup.get('confidence').status === 'INVALID' && evalForm.submitted }">
        <div class="row">
          <legend class="col-form-label col-sm-6 pt-0">The information provided in this presentation enhanced my confidence in treating PCOS.
            <span *ngIf="isRequiredField(confidenceCtrl)">*</span>
          </legend>
          <div class="col-sm-6 align-self-center text-center text-sm-right">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
              <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'confidence' + ranking.id" class="form-check-input mr-0 mb-1">
              <label class="form-check-label" [for]="'confidence' + ranking.id">{{ ranking.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('unansweredQuestion').status === 'INVALID' && evalForm.submitted }">
        <label for="unansweredQuestionCtrl">What one question remains unanswered after having participated in this activity?
          <span *ngIf="isRequiredField(unansweredQuestionCtrl)">*</span>
        </label>
        <textarea class="form-control" [formControl]="unansweredQuestionCtrl" id="unansweredQuestionCtrl"></textarea>
      </div>

      <div class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('infoLearned').status === 'INVALID' && evalForm.submitted }">
        <label for="infoLearnedCtrl">Please cite one new piece of information that you learned from this activity.
          <span *ngIf="isRequiredField(infoLearnedCtrl)">*</span>
        </label>
        <textarea class="form-control" [formControl]="infoLearnedCtrl" id="infoLearnedCtrl"></textarea>
      </div>

      <div class="form-group" [ngClass]="{ 'has-error': this.evalFormGroup.get('relatedTopics').status === 'INVALID' && evalForm.submitted }">
        <label for="relatedTopicsCtrl">What topics related to diabetes would you like to see offered in future continuing education activities?
          <span *ngIf="isRequiredField(relatedTopicsCtrl)">*</span>
        </label>
        <textarea class="form-control" id="relatedTopicsCtrl" [formControl]="relatedTopicsCtrl"></textarea>
      </div>

    </div>
  </div>
  <hr>
  <div class="row py-4">
    <div class="col-md-11 mx-auto">

      <h3>Commitment to Change</h3>


      <fieldset class="form-group" formGroupName="commitment" [ngClass]="{ 'has-error': this.evalFormGroup.get('commitment').status === 'INVALID' && evalForm.submitted }">
        <div class="row">
          <legend class="col-form-label col-sm-6 pt-0">After participating in this activity, I am committed to changing the way I treat my patients with PCOS.
            <span *ngIf="isRequiredField(commitmentCtrl)">*</span>
          </legend>
          <div class="col-sm-6 align-self-center text-center text-sm-right">
            <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let opt of yesNoOpts">
              <input type="radio" formControlName="commit" [value]="opt.title" [value]="opt.title" [id]="'commitment' + opt.id" class="form-check-input mr-0 mb-1">
              <label class="form-check-label" [for]="'commitment' + opt.id">{{ opt.title }}</label>
            </div>
          </div>
        </div>
      </fieldset>

      <div *ngIf="isRequiredField(commitmentDescriptionCtrl)" class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('commitmentDescription').status === 'INVALID' && evalForm.submitted }">
        <label for="commitmentDescriptionCtrl">If yes, please describe one thing you plan to change in your practice as a result of this activity.
          <span *ngIf="isRequiredField(commitmentDescriptionCtrl)">*</span>
        </label>
        <textarea class="form-control" id="commitmentDescriptionCtrl" [formControl]="commitmentDescriptionCtrl"></textarea>
      </div>

     


      <button type="submit" *ngIf="!evalSubmit" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold">
        Submit Evaluation
      </button>

      <a *ngIf="evalSubmit" (click)="openPDF(nameCtrl.value, certificateTypeCtrl.value)" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold">Download PDF</a>
    </div>
  </div>

</form>
</div>

<!--

  Eval Break

-->

<div *ngIf="evaltwo" class="container eval-sec ">
  <div class="row pb-3 ">
    <div class="col-md-11 mx-auto">
      <h1>Participant Information</h1>
      <p>Thank you for participating in
        <strong>
          <em>{{displayTitle()}}.
          </em>
        </strong>
    </div>
  </div>
  
  
  
  <form [formGroup]="evalFormGroup" [class.warning]="!evalFormGroup.valid && evalFormGroup.dirty" (ngSubmit)="onSubmit(evalFormGroup.value)" #evalForm="ngForm">
  <p class="form-error" *ngIf="!evalFormGroup.valid && evalForm.submitted"></p>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
        <div class="form-group pb-3">
          <label for="fname">First Name
          </label>
          <input type="text"  placeholder="First Name" id="fname" class="form-control">
          <label for="mname">Middle Initial
          </label>
          <input type="text"  placeholder="Mi" id="mname" class="form-control">
          <label for="lname">Last Name
          </label>
          <input type="text"  placeholder="Last Name" id="lname" class="form-control">
          <label for="pnum">Daytime Phone Number
          </label>
          <input type="text"  placeholder="##" id="pnum" class="form-control">
          <label for="email">Email Address
          </label>
          <input type="text"  placeholder="Email" id="email" class="form-control">
        </div>
        <fieldset class="form-group" formGroupName="profession"  [ngClass]="{ 'has-error': this.evalFormGroup.get('profession').status === 'INVALID' && evalForm.submitted }">
          <div class="row ">
            <legend class="col-form-label col-sm-3 pt-0">Profession
              <span *ngIf="isRequiredField(professionCtrl)">*</span>
            </legend>
            <div class="col-sm-9">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let pro of professions">
                <input type="radio" formControlName="proff" [value]="pro.title" [id]="'profession' + pro.id" class="form-check-input mb-1">
                <label class="form-check-label" [for]="'profession' + pro.id">{{ pro.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
        <div class="form-group row"  [ngClass]="{ 'has-error': this.evalFormGroup.get('specialty').status === 'INVALID' && evalForm.submitted }">
          <label for="specialty" class="col-sm-3 col-form-label">
            Specialty
            <span *ngIf="isRequiredField(specialtyCtrl)">*</span>
          </label>
          <div class="col-sm-9">
            <input type="text" id="specialty" [formControl]="specialtyCtrl" placeholder="" class="form-control">
          </div>
        </div>
  
  
        <fieldset class="form-group" formGroupName="avgPatient"  [ngClass]="{ 'has-error': this.evalFormGroup.get('avgPatient').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-3 pt-0">On average, how many patients with polycystic ovary syndrome (PCOS) do you see in 1 week?
              <span *ngIf="isRequiredField(professionCtrl)">*</span>
            </legend>
            <div class="col-sm-9 align-self-center">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let avg of patientAvg">
                <input type="radio" formControlName="patientCount" [value]="avg.title" [id]="'patientAvg' + avg.id" class="form-check-input mb-1">
                <label class="form-check-label" [for]="'patientAvg' + avg.id">{{ avg.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
      </div>
    </div>
    <hr>
    <div class="row py-4">
  
    <div class="col-md-11 mx-auto">
    
        <h3>Continuing Education Credit Attestation</h3>
    
    <p>
        <em>
            In order to receive credit for this program, please review the
            following statements and sign the attestation below.
        </em>
    </p>
    <p>
        <em>For Physicians</em>
    </p>
    <p>
        The Institute for Medical and Nursing Education, Inc. (IMNE), is accredited
        by the Accreditation Council for Continuing Medical Education (ACCME) to
        provide continuing medical education for physicians.
    </p>
    <p>
    IMNE designates this enduring activity for 0.75    <em>AMA PRA Category 1 Credits</em>™. Physicians should claim only the
        credit commensurate with the extent of their participation in the activity.
    </p>
    <p>
        <em>For Nurses</em>
    </p>
    <p>
        IMNE is accredited as a provider of nursing continuing professional
        development by the American Nurses Credentialing Centers (ANCCs)
        Commission on Accreditation.
    </p>
    <p>
        This educational program provides 0.75 contact hours of continuing
        education credit.
    </p><br/>
    <div class="row pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('time').status === 'INVALID' && evalForm.submitted }">
      <div class="col-sm-6">
        <label>I certify that I completed this educational activity. The actual amount of time I spent in this activity was:</label>
      </div>
      <div class="col-sm-6">
        <div class="form-group" [ngClass]="{ 'has-error': this.evalFormGroup.get('time').status === 'INVALID' && evalForm.submitted }">
          <label for="timeCtrl">
            Time
            <span *ngIf="isRequiredField(timeCtrl)">*</span>
          </label>
          <select class="form-control" id="timeCtrl" [formControl]="timeCtrl" ngModel>
            <option value="" disabled>Choose a time</option>
            <option *ngFor="let option of timeMultiselectOptions" [ngValue]="option.id" [id]="'time' + option.id">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  
    <fieldset class="form-group pb-3" formGroupName="certificateType" [ngClass]="{ 'has-error': this.evalFormGroup.get('certificateType').status === 'INVALID' && evalForm.submitted }">
      <div class="row">
        <legend class="col-form-label col-sm-5">CME/CE Certificate Type
          <span *ngIf="isRequiredField(certificateTypeCtrl)">*</span>
        </legend>
        <div class="col-sm-7  align-self-center text-center text-sm-right">
          <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let certType of certTypes">
            <input type="radio" formControlName="type" [value]="certType.title" [id]="'certificateType' + certType.id" class="form-check-input mr-0 mb-1">
            <label class="form-check-label" [for]="'certificateType' + certType.id">{{ certType.title }}</label>
          </div>
        </div>
      </div>
    </fieldset>
  
    <div class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('name').status === 'INVALID' && evalForm.submitted }">
      <label for="fullName">Enter your full name (as it should appear on your certificate).
        <span *ngIf="isRequiredField(nameCtrl)">*</span>
      </label>
      <input type="text" [formControl]="nameCtrl" placeholder="Full Name" id="fullName" class="form-control">
    </div>
    </div>
    </div>
    <hr>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
  
        <h3>Faculty and Content</h3>
        <p>
          <strong>Please evaluate the content of this activity.</strong>
        </p>
        <p class="rating-legend">Rating Scale:
          <span>1 = Strongly Disagree&nbsp;</span>
          <span>&nbsp;5 = Strongly Agree</span>
        </p>
        <p>
          <strong>
            
            
            
            
            <em>Donna H Ryan, MD, FTOS</em><br/>
            Discussing Weight With Women—Approaching the Topic of Weight Management
          </strong>
        </p>
  
        <fieldset class="form-group" formGroupName="evidenceBased" [ngClass]="{ 'has-error': this.evalFormGroup.get('evidenceBased').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-7 pt-0">Information provided in this presentation will promote the practice of evidence-based medicine
              <span *ngIf="isRequiredField(evidenceBasedCtrl)">*</span>
            </legend>
            <div class="col-sm-5 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'patientAvg' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'evidenceBased' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <fieldset class="form-group" formGroupName="biasFree" [ngClass]="{ 'has-error': this.evalFormGroup.get('biasFree').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The overall content of this activity was objective and free of bias.
              <span *ngIf="isRequiredField(biasFreeCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let opt of yesNoOpts">
                <input type="radio" formControlName="rank" [value]="opt.title" [id]="'biasFree' + opt.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'biasFree' + opt.id">{{ opt.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <div class="form-group" [ngClass]="{ 'has-error': this.evalFormGroup.get('biasExplanation').status === 'INVALID' && evalForm.submitted }">
          <label for="biasExplanation">If you answered "no" to the previous statement, please explain why.
            <span *ngIf="isRequiredField(biasExplanationCtrl)">*</span>
          </label>
          <textarea [formControl]="biasExplanationCtrl" class="form-control" id="biasExplanation"></textarea>
        </div>
  
      </div>
    </div>
    <hr>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
        <h3>Learning Objectives</h3>
        <p>
          <strong>Please evaluate how well the following learning objectives were met.</strong>
        </p>
        <p class="rating-legend">Rating Scale:
          <span>1 = Not Met&nbsp;</span>
          <span>&nbsp;5 = Met</span>
        </p>
  
        <fieldset class="form-group" formGroupName="describeGLP" [ngClass]="{ 'has-error': this.evalFormGroup.get('describeGLP').status === 'INVALID' && evalForm.submitted }" >
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">Implement behavioral intervention approaches for discussing weight loss goals and treatment approaches
              <span *ngIf="isRequiredField(describeGLPCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'describeGLP' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'describeGLP' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  

  
      </div>
    </div>
    <hr>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
  
        <h3>Overall Activity Evaluation</h3>
        <p>
          <strong>Please evaluate the overall activity.</strong>
        </p>
        <p class="rating-legend">Rating Scale:
          <span>1 = Strongly Disagree&nbsp;</span>
          <span>&nbsp;5 = Strongly Agree</span>
        </p>
  
        <fieldset class="form-group" formGroupName="relevant" [ngClass]="{ 'has-error': this.evalFormGroup.get('relevant').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The educational content was useful and relevant to my practice.
              <span *ngIf="isRequiredField(relevantCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'relevant' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'relevant' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <fieldset class="form-group" formGroupName="educational" [ngClass]="{ 'has-error': this.evalFormGroup.get('educational').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The educational format was effective.
              <span *ngIf="isRequiredField(educationalCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'educational' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'educational' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <fieldset class="form-group" formGroupName="knowledge" [ngClass]="{ 'has-error': this.evalFormGroup.get('knowledge').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The information provided in this presentation enhanced my knowledge and will enable me to provide better care for my patients with PCOS.
              <span *ngIf="isRequiredField(knowledgeCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'knowledge' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'knowledge' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <fieldset class="form-group pb-3" formGroupName="confidence" [ngClass]="{ 'has-error': this.evalFormGroup.get('confidence').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The information provided in this presentation enhanced my confidence in treating PCOS.
              <span *ngIf="isRequiredField(confidenceCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'confidence' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'confidence' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <div class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('unansweredQuestion').status === 'INVALID' && evalForm.submitted }">
          <label for="unansweredQuestionCtrl">What one question remains unanswered after having participated in this activity?
            <span *ngIf="isRequiredField(unansweredQuestionCtrl)">*</span>
          </label>
          <textarea class="form-control" [formControl]="unansweredQuestionCtrl" id="unansweredQuestionCtrl"></textarea>
        </div>
  
        <div class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('infoLearned').status === 'INVALID' && evalForm.submitted }">
          <label for="infoLearnedCtrl">Please cite one new piece of information that you learned from this activity.
            <span *ngIf="isRequiredField(infoLearnedCtrl)">*</span>
          </label>
          <textarea class="form-control" [formControl]="infoLearnedCtrl" id="infoLearnedCtrl"></textarea>
        </div>
  
        <div class="form-group" [ngClass]="{ 'has-error': this.evalFormGroup.get('relatedTopics').status === 'INVALID' && evalForm.submitted }">
          <label for="relatedTopicsCtrl">What topics related to diabetes would you like to see offered in future continuing education activities?
            <span *ngIf="isRequiredField(relatedTopicsCtrl)">*</span>
          </label>
          <textarea class="form-control" id="relatedTopicsCtrl" [formControl]="relatedTopicsCtrl"></textarea>
        </div>
  
      </div>
    </div>
    <hr>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
  
        <h3>Commitment to Change</h3>
  
  
        <fieldset class="form-group" formGroupName="commitment" [ngClass]="{ 'has-error': this.evalFormGroup.get('commitment').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">After participating in this activity, I am committed to changing the way I treat my patients with PCOS.
              <span *ngIf="isRequiredField(commitmentCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let opt of yesNoOpts">
                <input type="radio" formControlName="commit" [value]="opt.title" [value]="opt.title" [id]="'commitment' + opt.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'commitment' + opt.id">{{ opt.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <div *ngIf="isRequiredField(commitmentDescriptionCtrl)" class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('commitmentDescription').status === 'INVALID' && evalForm.submitted }">
          <label for="commitmentDescriptionCtrl">If yes, please describe one thing you plan to change in your practice as a result of this activity.
            <span *ngIf="isRequiredField(commitmentDescriptionCtrl)">*</span>
          </label>
          <textarea class="form-control" id="commitmentDescriptionCtrl" [formControl]="commitmentDescriptionCtrl"></textarea>
        </div>
  
       
  
  
        <button type="submit" *ngIf="!evalSubmit" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold">
          Submit Evaluation
        </button>
  
        <a *ngIf="evalSubmit" (click)="openPDF(nameCtrl.value, certificateTypeCtrl.value)" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold">Download PDF</a>
      </div>
    </div>
  
  </form>
  </div>

<!--

  Eval Break

-->

<div *ngIf="evalthree" class="container eval-sec ">
  <div class="row pb-3 ">
    <div class="col-md-11 mx-auto">
      <h1>Participant Information</h1>
      <p>Thank you for participating in
        <strong>
          <em>{{displayTitle()}}.
          </em>
        </strong>
    </div>
  </div>
  
  
  
  <form [formGroup]="evalFormGroup" [class.warning]="!evalFormGroup.valid && evalFormGroup.dirty" (ngSubmit)="onSubmit(evalFormGroup.value)" #evalForm="ngForm">
  <p class="form-error" *ngIf="!evalFormGroup.valid && evalForm.submitted"></p>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
        <div class="form-group pb-3">
          <label for="fname">First Name
          </label>
          <input type="text"  placeholder="First Name" id="fname" class="form-control">
          <label for="mname">Middle Initial
          </label>
          <input type="text"  placeholder="Mi" id="mname" class="form-control">
          <label for="lname">Last Name
          </label>
          <input type="text"  placeholder="Last Name" id="lname" class="form-control">
          <label for="pnum">Daytime Phone Number
          </label>
          <input type="text"  placeholder="##" id="pnum" class="form-control">
          <label for="email">Email Address
          </label>
          <input type="text"  placeholder="Email" id="email" class="form-control">
        </div>
        <fieldset class="form-group" formGroupName="profession"  [ngClass]="{ 'has-error': this.evalFormGroup.get('profession').status === 'INVALID' && evalForm.submitted }">
          <div class="row ">
            <legend class="col-form-label col-sm-3 pt-0">Profession
              <span *ngIf="isRequiredField(professionCtrl)">*</span>
            </legend>
            <div class="col-sm-9">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let pro of professions">
                <input type="radio" formControlName="proff" [value]="pro.title" [id]="'profession' + pro.id" class="form-check-input mb-1">
                <label class="form-check-label" [for]="'profession' + pro.id">{{ pro.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
        <div class="form-group row"  [ngClass]="{ 'has-error': this.evalFormGroup.get('specialty').status === 'INVALID' && evalForm.submitted }">
          <label for="specialty" class="col-sm-3 col-form-label">
            Specialty
            <span *ngIf="isRequiredField(specialtyCtrl)">*</span>
          </label>
          <div class="col-sm-9">
            <input type="text" id="specialty" [formControl]="specialtyCtrl" placeholder="" class="form-control">
          </div>
        </div>
  
  
        <fieldset class="form-group" formGroupName="avgPatient"  [ngClass]="{ 'has-error': this.evalFormGroup.get('avgPatient').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-3 pt-0">On average, how many patients with polycystic ovary syndrome (PCOS) do you see in 1 week?
              <span *ngIf="isRequiredField(professionCtrl)">*</span>
            </legend>
            <div class="col-sm-9 align-self-center">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let avg of patientAvg">
                <input type="radio" formControlName="patientCount" [value]="avg.title" [id]="'patientAvg' + avg.id" class="form-check-input mb-1">
                <label class="form-check-label" [for]="'patientAvg' + avg.id">{{ avg.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
      </div>
    </div>
    <hr>
    <div class="row py-4">
  
    <div class="col-md-11 mx-auto">
    
        <h3>Continuing Education Credit Attestation</h3>
    
    <p>
        <em>
            In order to receive credit for this program, please review the
            following statements and sign the attestation below.
        </em>
    </p>
    <p>
        <em>For Physicians</em>
    </p>
    <p>
        The Institute for Medical and Nursing Education, Inc. (IMNE), is accredited
        by the Accreditation Council for Continuing Medical Education (ACCME) to
        provide continuing medical education for physicians.
    </p>
    <p>
    IMNE designates this enduring activity for 0.75    <em>AMA PRA Category 1 Credits</em>™. Physicians should claim only the
        credit commensurate with the extent of their participation in the activity.
    </p>
    <p>
        <em>For Nurses</em>
    </p>
    <p>
        IMNE is accredited as a provider of nursing continuing professional
        development by the American Nurses Credentialing Centers (ANCCs)
        Commission on Accreditation.
    </p>
    <p>
        This educational program provides 0.75 contact hours of continuing
        education credit.
    </p><br/>
    <div class="row pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('time').status === 'INVALID' && evalForm.submitted }">
      <div class="col-sm-6">
        <label>I certify that I completed this educational activity. The actual amount of time I spent in this activity was:</label>
      </div>
      <div class="col-sm-6">
        <div class="form-group" [ngClass]="{ 'has-error': this.evalFormGroup.get('time').status === 'INVALID' && evalForm.submitted }">
          <label for="timeCtrl">
            Time
            <span *ngIf="isRequiredField(timeCtrl)">*</span>
          </label>
          <select class="form-control" id="timeCtrl" [formControl]="timeCtrl" ngModel>
            <option value="" disabled>Choose a time</option>
            <option *ngFor="let option of timeMultiselectOptions" [ngValue]="option.id" [id]="'time' + option.id">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  
    <fieldset class="form-group pb-3" formGroupName="certificateType" [ngClass]="{ 'has-error': this.evalFormGroup.get('certificateType').status === 'INVALID' && evalForm.submitted }">
      <div class="row">
        <legend class="col-form-label col-sm-5">CME/CE Certificate Type
          <span *ngIf="isRequiredField(certificateTypeCtrl)">*</span>
        </legend>
        <div class="col-sm-7  align-self-center text-center text-sm-right">
          <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let certType of certTypes">
            <input type="radio" formControlName="type" [value]="certType.title" [id]="'certificateType' + certType.id" class="form-check-input mr-0 mb-1">
            <label class="form-check-label" [for]="'certificateType' + certType.id">{{ certType.title }}</label>
          </div>
        </div>
      </div>
    </fieldset>
  
    <div class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('name').status === 'INVALID' && evalForm.submitted }">
      <label for="fullName">Enter your full name (as it should appear on your certificate).
        <span *ngIf="isRequiredField(nameCtrl)">*</span>
      </label>
      <input type="text" [formControl]="nameCtrl" placeholder="Full Name" id="fullName" class="form-control">
    </div>
    </div>
    </div>
    <hr>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
  
        <h3>Faculty and Content</h3>
        <p>
          <strong>Please evaluate the content of this activity.</strong>
        </p>
        <p class="rating-legend">Rating Scale:
          <span>1 = Strongly Disagree&nbsp;</span>
          <span>&nbsp;5 = Strongly Agree</span>
        </p>
        <p>
          <strong>
            
            
            
            
            <em>Donna H Ryan, MD, FTOS</em><br/>
            New and Emerging Solutions: Incretin-Based Therapies for Weight Management in Women With PCOS
            
          </strong>
        </p>
  
        <fieldset class="form-group" formGroupName="evidenceBased" [ngClass]="{ 'has-error': this.evalFormGroup.get('evidenceBased').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-7 pt-0">Information provided in this presentation will promote the practice of evidence-based medicine
              <span *ngIf="isRequiredField(evidenceBasedCtrl)">*</span>
            </legend>
            <div class="col-sm-5 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'patientAvg' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'evidenceBased' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <fieldset class="form-group" formGroupName="biasFree" [ngClass]="{ 'has-error': this.evalFormGroup.get('biasFree').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The overall content of this activity was objective and free of bias.
              <span *ngIf="isRequiredField(biasFreeCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let opt of yesNoOpts">
                <input type="radio" formControlName="rank" [value]="opt.title" [id]="'biasFree' + opt.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'biasFree' + opt.id">{{ opt.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <div class="form-group" [ngClass]="{ 'has-error': this.evalFormGroup.get('biasExplanation').status === 'INVALID' && evalForm.submitted }">
          <label for="biasExplanation">If you answered "no" to the previous statement, please explain why.
            <span *ngIf="isRequiredField(biasExplanationCtrl)">*</span>
          </label>
          <textarea [formControl]="biasExplanationCtrl" class="form-control" id="biasExplanation"></textarea>
        </div>
  
      </div>
    </div>
    <hr>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
        <h3>Learning Objectives</h3>
        <p>
          <strong>Please evaluate how well the following learning objectives were met.</strong>
        </p>
        <p class="rating-legend">Rating Scale:
          <span>1 = Not Met&nbsp;</span>
          <span>&nbsp;5 = Met</span>
        </p>
  
        <fieldset class="form-group" formGroupName="describeGLP" [ngClass]="{ 'has-error': this.evalFormGroup.get('describeGLP').status === 'INVALID' && evalForm.submitted }" >
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">Discuss current and emerging incretin-based agents and bariatric surgery for the management of obesity and related disorders in women with PCOS
              <span *ngIf="isRequiredField(describeGLPCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'describeGLP' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'describeGLP' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
       
  
      </div>
    </div>
    <hr>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
  
        <h3>Overall Activity Evaluation</h3>
        <p>
          <strong>Please evaluate the overall activity.</strong>
        </p>
        <p class="rating-legend">Rating Scale:
          <span>1 = Strongly Disagree&nbsp;</span>
          <span>&nbsp;5 = Strongly Agree</span>
        </p>
  
        <fieldset class="form-group" formGroupName="relevant" [ngClass]="{ 'has-error': this.evalFormGroup.get('relevant').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The educational content was useful and relevant to my practice.
              <span *ngIf="isRequiredField(relevantCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'relevant' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'relevant' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <fieldset class="form-group" formGroupName="educational" [ngClass]="{ 'has-error': this.evalFormGroup.get('educational').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The educational format was effective.
              <span *ngIf="isRequiredField(educationalCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'educational' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'educational' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <fieldset class="form-group" formGroupName="knowledge" [ngClass]="{ 'has-error': this.evalFormGroup.get('knowledge').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The information provided in this presentation enhanced my knowledge and will enable me to provide better care for my patients with PCOS.
              <span *ngIf="isRequiredField(knowledgeCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'knowledge' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'knowledge' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <fieldset class="form-group pb-3" formGroupName="confidence" [ngClass]="{ 'has-error': this.evalFormGroup.get('confidence').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">The information provided in this presentation enhanced my confidence in treating PCOS.
              <span *ngIf="isRequiredField(confidenceCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let ranking of rankingOpts">
                <input type="radio" formControlName="rank" [value]="ranking.title" [id]="'confidence' + ranking.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'confidence' + ranking.id">{{ ranking.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <div class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('unansweredQuestion').status === 'INVALID' && evalForm.submitted }">
          <label for="unansweredQuestionCtrl">What one question remains unanswered after having participated in this activity?
            <span *ngIf="isRequiredField(unansweredQuestionCtrl)">*</span>
          </label>
          <textarea class="form-control" [formControl]="unansweredQuestionCtrl" id="unansweredQuestionCtrl"></textarea>
        </div>
  
        <div class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('infoLearned').status === 'INVALID' && evalForm.submitted }">
          <label for="infoLearnedCtrl">Please cite one new piece of information that you learned from this activity.
            <span *ngIf="isRequiredField(infoLearnedCtrl)">*</span>
          </label>
          <textarea class="form-control" [formControl]="infoLearnedCtrl" id="infoLearnedCtrl"></textarea>
        </div>
  
        <div class="form-group" [ngClass]="{ 'has-error': this.evalFormGroup.get('relatedTopics').status === 'INVALID' && evalForm.submitted }">
          <label for="relatedTopicsCtrl">What topics related to diabetes would you like to see offered in future continuing education activities?
            <span *ngIf="isRequiredField(relatedTopicsCtrl)">*</span>
          </label>
          <textarea class="form-control" id="relatedTopicsCtrl" [formControl]="relatedTopicsCtrl"></textarea>
        </div>
  
      </div>
    </div>
    <hr>
    <div class="row py-4">
      <div class="col-md-11 mx-auto">
  
        <h3>Commitment to Change</h3>
  
  
        <fieldset class="form-group" formGroupName="commitment" [ngClass]="{ 'has-error': this.evalFormGroup.get('commitment').status === 'INVALID' && evalForm.submitted }">
          <div class="row">
            <legend class="col-form-label col-sm-6 pt-0">After participating in this activity, I am committed to changing the way I treat my patients with PCOS.
              <span *ngIf="isRequiredField(commitmentCtrl)">*</span>
            </legend>
            <div class="col-sm-6 align-self-center text-center text-sm-right">
              <div class="form-check form-check-inline p-lg-2 flex-column" *ngFor="let opt of yesNoOpts">
                <input type="radio" formControlName="commit" [value]="opt.title" [value]="opt.title" [id]="'commitment' + opt.id" class="form-check-input mr-0 mb-1">
                <label class="form-check-label" [for]="'commitment' + opt.id">{{ opt.title }}</label>
              </div>
            </div>
          </div>
        </fieldset>
  
        <div *ngIf="isRequiredField(commitmentDescriptionCtrl)" class="form-group pb-3" [ngClass]="{ 'has-error': this.evalFormGroup.get('commitmentDescription').status === 'INVALID' && evalForm.submitted }">
          <label for="commitmentDescriptionCtrl">If yes, please describe one thing you plan to change in your practice as a result of this activity.
            <span *ngIf="isRequiredField(commitmentDescriptionCtrl)">*</span>
          </label>
          <textarea class="form-control" id="commitmentDescriptionCtrl" [formControl]="commitmentDescriptionCtrl"></textarea>
        </div>
  
       
  
  
        <button type="submit" *ngIf="!evalSubmit" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold">
          Submit Evaluation
        </button>
  
        <a *ngIf="evalSubmit" (click)="openPDF(nameCtrl.value, certificateTypeCtrl.value)" class="btn btn-secondary d-block mx-auto w-50 font-weight-bold">Download PDF</a>
      </div>
    </div>
  
  </form>
  </div>