import { Component, OnDestroy, OnInit } from '@angular/core';
//import { e } from '@angular/core/src/render3';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import {
  ICaseStat,
  IOption,
  ISelectedAnswer,
  ITestQuestion,
  ITestRequest,
  ITopic,
  ITopicStatusRequest,
} from '../../models/topic.model';
import { IUserStatus } from '../../models/user-status.model';
import { IUser } from '../../models/user.model';
import { AuthService } from '../../services/auth/auth.service';
import { ModalLauncherService } from '../../services/modal-launcher/modal-launcher.service';
import { TopicService } from '../../services/topic/topic.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss'],
})
export class CaseStudyComponent implements OnInit, OnDestroy {
  topicData: ITopic;
  questions: ITestQuestion[];
  caseStats: ICaseStat[];
  topicId;
  questionId: number;
  testType: string;
  vidone = false;
  vidtwo = false;
  vidthree = false;

  loading = false;
  submissionMessage: string;

  user: IUser;
  userStatus: IUserStatus;
  userCurrentTopicId: number;
  answerArray: Array<ISelectedAnswer> = [];
  isSubmitted = false;

  private subs: Subscription[] = [];

  constructor(
    private topicService: TopicService,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalLauncherService
  ) {}

  ngOnInit() {
    this.subs.push(
      ...[
        this.subscribeToCurrentTopic(),
        this.subscribeToRationaleData(),
      ]
    );
    

      if(this.topicId===12){
        this.vidone = true;
      }else if(this.topicId===13){
        this.vidtwo = true;
      }else if(this.topicId===14){
        this.vidthree = true;
      }
    this.validateUserTopic();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe);

    this.topicService.resetQuizValidation(this.questions);
  }

  subscribeToCurrentTopic() {
    return this.route.params.subscribe(params => {
      this.topicId = parseInt(params['topicId'], 10);
      this.topicData = this.topicService.getTopicById(this.topicId);
      this.questions = this.topicData.caseQuestions;
      this.caseStats = this.topicData.caseContent;
    });
  }

  subscribeToCurrentUser() {
    // return this.authService.getCurretUser().subscribe(user => {
    //   this.user = user.current;
    //   this.userService.getCurrentUserstatus(this.user).subscribe(status => {
    //     this.userStatus = status;
    //   });
    // });
  }

  subscribeToRationaleData() {
    return this.topicService.currentRationaleTestType$.subscribe(t => {
      this.testType = t;
      this.topicService.currentRationaleQuestionId$.subscribe(q => {
        this.questionId = q;
      });
    });
  }

  validateUserTopic() {
    this.userService.topicSource.take(1).subscribe(id => {
      this.userCurrentTopicId = id;
    });
    if (this.userCurrentTopicId && this.userCurrentTopicId !== this.topicId) {
      this.userService.setUserTopic(this.userCurrentTopicId);
      this.router.navigate(['/casestudy', this.userCurrentTopicId]);
    }
  }


  onChange(question: ITestQuestion, questionOption: IOption) {
    this.answerArray = this.answerArray.filter(entry => {
      return entry.questionId !== question.id;
    });

    const questionEntry: ISelectedAnswer = {
      questionId: question.id,
      optionValue: questionOption.value,
    };
    this.answerArray.push(questionEntry);

    question.options = this.topicService.validateAnswer(
      question,
      questionOption
    );
  }

  onVideoEnded() {
    this.isSubmitted =true;
  }

  goToPostTest() {
    this.router.navigate(['/posttest', this.topicId]);
  }

  onSubmit() {
    this.questions = this.topicService.validateQuestionOptions(this.questions);
    const formSubmit: ITestRequest = {
        TopicId: this.topicData.TopicID,
        answers: this.answerArray.map(a => a.optionValue),
        date: new Date().toString(),
        user: {
          email: this.user.email,
          score: this.topicService.calculateScore(this.questions),
          userAccountId: this.user.userAccountID,
          username: this.user.username,
        },
      },
      topicStatusUpdate: ITopicStatusRequest = {
        TopicId: this.topicData.TopicID,
        Status: 'Incomplete',
        user: this.user,
      };

    this.topicService
      .submitTopicTest(formSubmit, this.user.Token, 'casestudy')
      .subscribe(
        data => {
          this.topicService.submitTopicStatus(topicStatusUpdate).subscribe(
            topicData => {
              this.userService.updateUserTopicStatusByTopicId(
                this.userStatus,
                topicStatusUpdate.TopicId,
                topicStatusUpdate.Status
              );
              this.authService.updateUserToken(topicData, this.user);
              this.submissionMessage =
                'Success. Routing you to the posttest...';
              this.isSubmitted = true;
            },
            error => {
              console.log('error updating user status');
              return Observable.throw(error);
            }
          );
        },
        error => {
          console.log('error submitting pretest');
          this.submissionMessage =
            'Sorry, there was an error submitting your test.  Please try again.';
          return Observable.throw(error);
        }
      );
  }

  onViewRationale(id: number) {
    this.topicService.updateQuestionId(id);
    this.topicService.updateTestType('casestudy');

    this.showRationaleModal();
  }
  showRationaleModal() {
    this.modalService.launchRationaleModal(
      this.questionId,
      this.testType,
      this.topicService.getRationaleByTestTypeAndQuestionId(
        this.topicId,
        this.questionId,
        this.testType
      )
    );
  }
}
